document.addEventListener("turbo:load", function() {
    $('.toast').toast("show");

    if(typeof tinymce != "undefined") {
        tinymce.remove();
        tinymce.init({ selector: ".tinymce" });
    }

    Prism.highlightAll();
});
